/* Reset de estilo */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body, html {
  width: 100%;
  height: 100%;
  font-family: 'Arial', sans-serif;
  background-color: #F7F7F7;
  color: #333333;
}

/* Cabeçalho e botões do menu */
header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  background-color: #fff;
  border-bottom: 2px solid #E63946;
}

header a {
  text-decoration: none;
  color: #E63946;
  font-size: 1rem;
  font-weight: bold;
  margin: 0 15px;
  padding: 8px 16px;
  border: 2px solid #E63946;
  border-radius: 8px;
  background-color: transparent;
  transition: all 0.3s ease;
}

header a:hover,
header a:focus {
  background-color: #E63946;
  color: #fff;
  transform: translateY(-2px);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  outline: none;
}

/* Título Principal */
.pg-sobre-header {
  position: relative;
  width: 100%;
  min-height: 400px;
  background: url('../../assets/bg1.jpg') no-repeat center center / cover;
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  border-bottom: 4px solid #E63946;
}

.pg-sobre-header::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1;
}

.pg-sobre-header h1 {
  font-size: 3.5rem;
  font-weight: bold;
  margin: 0;
  z-index: 2;
  text-align: center;
  line-height: 1.4;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4);
}

.pg-sobre-header p {
  font-size: 1.5rem;
  margin: 10px 0 0;
  z-index: 2;
  text-align: center;
}

/* Seção de Benefícios */
.benefits {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Permite que os itens se expandam sem limite */
  gap: 20px; /* Espaçamento uniforme entre os cards */
  width: 100%; /* Ocupa toda a largura disponível */
  max-width: 100%; /* Remove limite de largura máxima */
  margin: 40px auto; /* Centraliza a seção horizontalmente */
  padding: 20px;
  background-color: #FFFFFF;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.benefit-item {
  background: #FFFFFF;
  border: 1px solid #CCCCCC;
  border-radius: 12px;
  text-align: center;
  padding: 30px 20px;
  transition: all 0.3s ease;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
}

.benefit-item:hover,
.benefit-item:focus {
  transform: translateY(-5px);
  background: #F9F9F9;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  outline: none;
}

.benefit-icon {
  font-size: 3rem;
  margin-bottom: 20px;
  color: #E63946;
}

.benefit-title {
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333333;
}

.benefit-description {
  font-size: 1rem;
  color: #666666;
  line-height: 1.5;
}

/* Call to Action */
.cta-section {
  text-align: center;
  padding: 40px 20px;
  background: #F7F7F7;
  color: #333333;
}

.cta-section p {
  font-size: 1.8rem;
  margin-bottom: 30px;
  font-weight: bold;
}

.cta-button {
  display: inline-block;
  background-color: #E63946;
  color: #FFFFFF;
  padding: 14px 28px;
  font-size: 1.2rem;
  text-decoration: none;
  border-radius: 8px;
  transition: all 0.3s ease;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.cta-button:hover,
.cta-button:focus {
  background-color: #C72D3F;
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.2);
  transform: translateY(-3px);
  outline: none;
}

/* Responsividade */
@media (max-width: 768px) {
  .pg-sobre-header h1 {
    font-size: 2.5rem;
  }

  .pg-sobre-header p {
    font-size: 1.2rem;
  }

  .benefits {
    gap: 10px;
    padding: 10px;
  }

  .benefit-item {
    padding: 20px;
  }

  .cta-section p {
    font-size: 1.5rem;
  }

  .cta-button {
    font-size: 1rem;
    padding: 12px 24px;
  }
}
