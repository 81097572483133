/* Reset de estilo */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body, html {
    width: 100%;
    height: 100%;
}

/* Cabeçalho */
header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    background-color: #ffffff;
    border-bottom: 2px solid #E63946;
}

header a {
    text-decoration: none;
    color: #E63946;
    font-size: 1.1rem;
    font-weight: bold;
    margin: 0 10px;
    padding: 10px 15px;
    border: 2px solid transparent;
    border-radius: 8px;
    background-color: #ffffff;
    transition: all 0.3s ease;
}

header a:hover {
    background-color: #E63946;
    color: #ffffff;
    border: 2px solid #E63946;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

header a:focus {
    outline: 2px dashed #E63946;
    outline-offset: 4px;
}

/* Seção do título */
.pg-faleconosco-header {
    position: relative;
    background: url('../../assets/bg2.jpg') no-repeat center center / cover;
    color: #ffffff;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow: hidden;
}

.pg-faleconosco-header::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 1;
}

.pg-faleconosco-header h1,
.pg-faleconosco-header p {
    position: relative;
    z-index: 2;
    text-align: center;
}

.pg-faleconosco-header h1 {
    font-size: 3rem;
}

.pg-faleconosco-header p {
    font-size: 1.4rem;
    margin-top: 10px;
}

/* Seção dos cards */
.contact-section {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 20px;
    max-width: 1200px;
    margin: 20px auto;
    padding: 20px;
}

.contact-item {
    background: #ffffff;
    border: 1px solid #CCCCCC;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    transition: transform 0.3s ease, background-color 0.3s ease;
}

.contact-item:hover {
    transform: scale(1.05);
    background: #EFEFEF;
}

.contact-icon,
.qr-code {
    margin-bottom: 15px;
}

.contact-icon {
    width: 80px;
    height: 80px;
    animation: float 2s infinite ease-in-out;
}

.qr-code {
    width: 180px;
    border: 2px solid #E63946;
    border-radius: 8px;
    animation: pulse 1.5s infinite;
}

@keyframes pulse {
    0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.07);
    }
}

@keyframes float {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-8px);
    }
}

.contact-title {
    font-size: 1.2rem;
    font-weight: bold;
    color: #666666;
    margin-bottom: 5px;
}

.contact-number {
    font-size: 1.4rem;
    color: #E63946;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin: 5px 0;
}

.contact-number img {
    width: 24px;
}

.contact-link {
    font-size: 1.2rem;
    color: #E63946;
    text-decoration: none;
    transition: color 0.3s ease;
}

.contact-link:hover {
    color: #C72D3F;
}

.contact-link:focus {
    outline: 2px dashed #E63946;
    outline-offset: 4px;
}

/* Rodapé */
footer {
    background-color: #E63946;
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 2;
    font-size: 1rem;
    padding: 10px;
}

/* Responsividade */
@media (max-width: 768px) {
    .pg-faleconosco-header h1 {
        font-size: 2rem;
    }

    .pg-faleconosco-header p {
        font-size: 1rem;
    }

    .contact-section {
        gap: 10px;
    }

    .contact-item {
        padding: 10px;
    }
}
