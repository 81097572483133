/* Reset de estilo */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body, html {
  width: 100%;
  height: 100%;
  font-family: 'Arial', sans-serif;
  background-color: #f7f7f7;
  color: #333333;
}

/* Cabeçalho e botões do menu */
header a {
  text-decoration: none;
  color: #e63946; /* Texto vermelho */
  font-size: 1rem;
  font-weight: bold;
  margin: 0 15px;
  padding: 8px 16px;
  border: 2px solid #e63946; /* Borda vermelha */
  border-radius: 8px;
  background-color: transparent; /* Fundo transparente */
  transition: all 0.3s ease;
}

header a:hover,
header a:focus {
  background-color: #e63946; /* Fundo vermelho no hover */
  color: #fff; /* Texto branco no hover */
  transform: translateY(-2px); /* Efeito de movimento */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Sombra */
}

/* Rodapé */
footer {
  width: 100%;
  background-color: #e63946;
  color: #ffffff;
  text-align: center;
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
  position: fixed;
  bottom: 0;
  left: 0;
}
